import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { BDEType } from "src/app/enums/bde-type.enum";
import { ModalID } from "src/app/enums/modal-id.enum";
import { DifferenceStatus } from "src/app/enums/difference-status.enum";
import { UnitCode } from "src/app/enums/unit-codes.enum";
import { Link } from "src/app/interfaces/link";
import { PreviewTable } from "src/app/interfaces/table-preview";
import { AppService } from "src/app/services/local/app/app.service";
import { ModalService } from "src/app/services/local/modal/modal.service";
import { MODAL_CONFIG_HEADER, UNIT_ID_LINK_PREFIX } from "src/app/utils/consts";
import {
  isUserAdmin,
  sanitizeUnitCodeForTemplateID,
} from "src/app/utils/functions";
import { environment } from "src/environments/environment";
import { ModalConfigComponent } from "../modal-config/modal-config.component";
import { ThresholdStatus } from "src/app/enums/threshold-status.enum";

@Component({
  selector: "app-table-preview",
  templateUrl: "./table-preview.component.html",
})
export class TablePreviewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public readonly UNIT_ID_LINK_PREFIX = UNIT_ID_LINK_PREFIX;
  public readonly ENVIRONMENT = environment;
  public readonly BDEType = BDEType;
  public readonly LINKS_LIST_NODE_ID_PREFIX = "links-list-";
  public readonly LINKS_LIST_MENU_OPEN_CLASS = "menu-open";
  public readonly sanitizeUnitCodeForTemplateID = sanitizeUnitCodeForTemplateID;
  public readonly DifferenceStatus = DifferenceStatus;
  public readonly ThresholdStatus = ThresholdStatus;

  public currentTime: Date;
  public previousDeltaList: number[] = [];
  public isUserAdmin: boolean = false;

  @Input() dataset: PreviewTable[];
  @Input() auctionID: number;
  @Input() routedLinks: boolean = false;

  constructor(private app: AppService, private modalService: ModalService) {}

  ngOnInit() {
    if (this.dataset) {
      this.dataset.forEach((unit) => {
        this.previousDeltaList[unit.unitCode] = unit.delta;
      });
    }
    this.getUserData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dataset?.previousValue && changes?.dataset?.currentValue) {
      this.updatePreviousDeltaValues(changes.dataset.previousValue);
    }

    if (changes?.dataset?.currentValue) {
      this.getExternalLinks();
      this.getUnitsOptions();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private getUserData(): void {
    this.app
      .getUserData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((userData) => {
        this.isUserAdmin = isUserAdmin(userData);
      });
  }

  private updatePreviousDeltaValues(previousUnitsData: PreviewTable[]): void {
    (previousUnitsData as PreviewTable[]).forEach((unit) => {
      this.previousDeltaList[unit.unitCode] = unit.delta;
    });
  }

  private getExternalLinks(): void {
    this.app
      .getExternalLinks()
      .pipe(
        takeUntil(this.onDestroy$),
        filter((linksList) => Boolean(linksList))
      )
      .subscribe((linksList) => {
        this.dataset?.forEach((unit) => {
          unit.externalLinks = linksList[unit.unitCode] as Link[];
        });
      });
  }

  private getUnitsOptions(): void {
    this.app.getUnitsOptions().pipe(
      first()
    ).subscribe(unitsOptions => {
      this.dataset.forEach(unit => unit.options = unitsOptions[unit.unitCode])
    });
  }

  public isDeltaAboveTreshold(unit: PreviewTable): boolean {
    const previousDelta = this.previousDeltaList[unit.unitCode];
    const currentDelta = unit.delta;
    const threshold = unit.options.thresholdValue;
    const percentage = Math.abs((previousDelta / 100) * threshold);
    return currentDelta === null ||
      previousDelta === null ||
      currentDelta === previousDelta
      ? false
      : currentDelta > previousDelta + percentage;
  }

  public isDeltaBelowTreshold(unit: PreviewTable): boolean {
    const previousDelta = this.previousDeltaList[unit.unitCode];
    const currentDelta = unit.delta;
    const threshold = unit.options.thresholdValue;
    const percentage = Math.abs((previousDelta / 100) * threshold);
    return currentDelta === null ||
      previousDelta === null ||
      currentDelta === previousDelta
      ? false
      : currentDelta < previousDelta - percentage;
  }

  public openConfigModal(unitCode: UnitCode): void {
    const currentModalConfig = {
      id: ModalID.CONFIG,
      componentType: ModalConfigComponent,
      content: {
        head: {
          ...MODAL_CONFIG_HEADER,
          adminConfigSelectInitialValue: unitCode,
          auctionID: this.auctionID,
        },
      },
    };
    this.modalService.openModal(currentModalConfig);
  }

  public toggleLinksList(unitCode: UnitCode): void {
    const targetID = sanitizeUnitCodeForTemplateID(
      this.LINKS_LIST_NODE_ID_PREFIX + unitCode
    );
    const targetNode = document.querySelector("#" + targetID);
    targetNode?.classList.toggle(this.LINKS_LIST_MENU_OPEN_CLASS);
  }

  public hideLinksList(unitCode: UnitCode, event: Event): void {
    const targetID = sanitizeUnitCodeForTemplateID(
      this.LINKS_LIST_NODE_ID_PREFIX + unitCode
    );
    const targetNode = document.querySelector("#" + targetID);
    if (!targetNode?.contains(event.target as HTMLElement)) {
      targetNode?.classList.remove(this.LINKS_LIST_MENU_OPEN_CLASS);
    }
  }
}
