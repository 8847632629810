import { Env } from 'src/app/enums/environment.enum';

export const environment = {
  id: Env.PROD,
  production: true,
  baseUrls: {
    httpLoaderPrefix: './assets/i18n/',
    httpLoaderSuffix: '.json',
    apiBaseUrl: 'https://c4uv4c9sag-vpce-037464205e71f95ff.execute-api.eu-central-1.amazonaws.com/prod/backend/',
    authenticationUrl: {
      base: 'https://auth-prod-prod.auth.eu-central-1.amazoncognito.com/oauth2/authorize?',
      options: {
        identity_provider: 'Azure',
        response_type: 'TOKEN',
        scope: 'aws.cognito.signin.user.admin+email+openid+profile',
        redirect_uri: window.location.protocol + '//' + window.location.host, //'https://app.prod.pdp.alperia.digital'
      }
    },
    refreshTokenUrl: {
      base: 'https://auth-prod-prod.auth.eu-central-1.amazoncognito.com/oauth2/token'
    }
  },
};
