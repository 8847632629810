<div class="table table-preview" role="table">
  <div class="table-head table-preview-head" role="rowgroup">
    <div class="table-head-row table-preview-head-row" role="row">
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-name typo-body-2 typo-bold"
        [innerText]="'TABLE-PREVIEW-HEAD:unit' | translate">
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-icon cell-icon-alarm">
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-icon cell-icon-mib">
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-icon cell-icon-bde">
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-icon cell-icon-note">
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-bde cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:bde' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:bde' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-pv cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:pv' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:pv' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-pvm cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:pvm' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:pvm' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-power cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:power' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:power' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-delta cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:delta' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:delta' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-powermin cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:powermin' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:powermin' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-powermax cell-data typo-body-2 typo-bold">
        <span
          class="tooltip tooltip-bottom tooltip-auto-max"
          [attr.data-tooltip]="'TABLE-PREVIEW-HEAD--TOOLTIP:powermax' | translate"
          [innerText]="'TABLE-PREVIEW-HEAD:powermax' | translate">
        </span>
      </div>
      <div
        role="cell"
        class="table-head-cell table-preview-head-cell cell-icon cell-icon-link">
      </div>
    </div>
  </div>
  <div class="table-body table-preview-body" role="rowgroup">
    <ng-container *ngIf="!!dataset">
      <div class="table-body-row table-preview-body-row" *ngFor="let unit of dataset; index as unitIndex" role="row">

        <i *ngIf="isUserAdmin" class="icon-gear icon-small icon-dark-gray icon-clickable icon-anim table-preview-icon-config" (click)="openConfigModal(unit.unitCode)"></i>

        <div class="table-body-cell table-preview-body-cell cell-name typo-body-2 tooltip-top tooltip-large" [attr.data-tooltip]="unit.unitCode" role="cell">
          <a
            *ngIf="routedLinks"
            [routerLink]="['/auction']"
            [queryParams]="{id: auctionID, scrollTo: UNIT_ID_LINK_PREFIX + unitIndex}"
            [innerText]="unit.unitCode | translate"
            class="typo-textlink typo-bold typo-uppercase text-trim-1">
          </a>
          <a
            *ngIf="!routedLinks"
            [routerLink]=""
            [queryParams]="{scrollTo: UNIT_ID_LINK_PREFIX + unitIndex}"
            [queryParamsHandling]="'merge'"
            [innerText]="unit.unitCode | translate"
            [attr.data-scrollto]="UNIT_ID_LINK_PREFIX + unitIndex"
            class="typo-textlink typo-bold typo-uppercase text-trim-1">
          </a>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-icon cell-icon-alarm typo-body-2" role="cell">
          <i
            *ngIf="unit.unbalancedAlarmStatus === ThresholdStatus.CRITICAL || (unit.isQuarterly && unit.unbalancedAlarmStatus === ThresholdStatus.WARNING)"
            class="icon-warning icon-small icon-white icon-box icon-box-small icon-box-no-shadow icon-box-no-border"
            [ngClass]="{
              'icon-box-green': unit.differenceStatus === DifferenceStatus.POSITIVE,
              'icon-box-red': unit.differenceStatus === DifferenceStatus.NEGATIVE
            }">
          </i>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-icon cell-icon-mib typo-body-2" role="cell">
          <span
            class="table-preview-icon-text"
            [ngClass]="{'active': unit.mibs.current, 'wave-animation': unit.mibs.isIncoming}"
            *ngIf="unit.mibs.current || unit.mibs.isIncoming">
            <span>M</span>
            <span>I</span>
            <span>B</span>
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-icon cell-icon-bde typo-body-2" role="cell">
          <span
            class="table-preview-icon-text"
            [ngClass]="{'active': unit.bde.current, 'wave-animation': unit.bde.isIncoming}"
            *ngIf="unit.bde.current || unit.bde.isIncoming">
            <span>B</span>
            <span>D</span>
            <span>E</span>
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-icon cell-icon-note typo-body-2" role="cell">
          <span
            class="table-preview-icon-note tooltip-right tooltip-auto"
            [attr.data-tooltip]="'TABLE-PREVIEW--TOOLTIP:note' | translate"
            *ngIf="unit.withNote">
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-bde cell-data" role="cell">
          <span
            *ngIf="unit.bde.current"
            class="typo-body-2 tooltip-top tooltip-auto"
            [innerText]="unit.bde.current.value | floatTrim | numberSign"
            [attr.data-tooltip]="unit.bde.current.value">
          </span>
          <span class="table-preview-trend" *ngIf="unit.bde.current?.inProgress && unit.bde.current?.type === BDEType.RAMP">
            <span class="table-preview-trend-up"   *ngIf="unit.bde.current.ascendant"></span>
            <span class="table-preview-trend-down" *ngIf="!unit.bde.current.ascendant"></span>
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-pv cell-data" role="cell">
          <span
            class="typo-body-2 tooltip-top tooltip-auto"
            [innerText]="unit.pv | floatTrim"
            [attr.data-tooltip]="unit.pv">
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-pvm cell-data" role="cell">
          <span
            class="typo-body-2 tooltip-top tooltip-auto"
            [innerText]="unit.pvm | floatTrim"
            [attr.data-tooltip]="unit.pvm">
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-power cell-data" role="cell">
          <span
            class="typo-body-2 typo-bold tooltip-top tooltip-auto"
            [innerText]="unit.power | floatTrim"
            [attr.data-tooltip]="unit.power">
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-delta cell-data" role="cell">
          <span
            class="typo-body-2 tooltip-top tooltip-auto"
            [innerText]="unit.delta | floatTrim | numberSign"
            [attr.data-tooltip]="unit.delta">
          </span>
          <span class="table-preview-trend">
            <span class="table-preview-trend-up"   *ngIf="isDeltaAboveTreshold(unit)"></span>
            <span class="table-preview-trend-down" *ngIf="isDeltaBelowTreshold(unit)"></span>
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-powermin cell-data" role="cell">
          <span
            class="typo-body-2 tooltip-top tooltip-auto"
            [innerText]="unit.powerMin | floatTrim"
            [attr.data-tooltip]="unit.powerMin">
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-powermax cell-data" role="cell">
          <span
            class="typo-body-2 tooltip-top tooltip-auto"
            [innerText]="unit.powerMax | floatTrim"
            [attr.data-tooltip]="unit.powerMax">
          </span>
        </div>
        <div class="table-body-cell table-preview-body-cell cell-icon cell-icon-link typo-body-2" role="cell">
          <span
            [id]="sanitizeUnitCodeForTemplateID(LINKS_LIST_NODE_ID_PREFIX + unit.unitCode)"
            class="table-preview-icon-link"
            *ngIf="unit.externalLinks?.length > 0"
            (click)="toggleLinksList(unit.unitCode)">
            <ng-container *ngIf="unit.externalLinks?.length === 1; then singleLink else linksMenu"></ng-container>
            <ng-template #singleLink>
              <a
                [href]="unit.externalLinks[0]?.url"
                target="_blank">
                <i class="icon-paperclip icon-dark-gray icon-small"></i>
              </a>
            </ng-template>
            <ng-template #linksMenu>
              <!-- <span class="table-preview-icon-link-label" [innerText]="unit.nLinks"></span> -->
              <i class="icon-paperclip icon-dark-gray icon-small"></i>
              <div class="card table-preview-icon-link-menu" (clickOutside)="hideLinksList(unit.unitCode, $event)">
                <ng-container *ngFor="let externalLink of unit.externalLinks">
                  <a [href]="externalLink.url" target="_blank" [innerText]="externalLink.label"></a>
                </ng-container>
              </div>
            </ng-template>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>